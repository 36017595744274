import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Card,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    cardStyle: {
      height: "40px",
      boxShadow: "none",
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.background.entity_background,
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
  }),
);

export interface AvailabilitySelectionModalProps {
  shiftReportNode: any;
}

/* This creates the banking and summery tables. This component only works if the key is banking or summery. */
const BankingAndSummeryInfo: React.FunctionComponent<
  AvailabilitySelectionModalProps
> = ({ shiftReportNode }) => {
  const [reportName, setTableName] = useState("");
  const [nodes, setNodes] = useState<any>([]);

  useEffect(() => {
    const { tableName, data } = shiftReportNode;
    setTableName(tableName);
    setNodes(data);
  }, [shiftReportNode]);

  const handleTooltip = (title: string) => {
    if (title === "Cash Payments Online") {
      return "Cash online orders, paid by cash, at the counter";
    } else if (title === "Cash Payments In-Store") {
      return "Walk-in/Phone orders, paid by cash, at the counter";
    } else if (title === "Card Payments Online") {
      return "Cash online orders, paid by card, at the counter";
    } else {
      return "Walk-in/Phone orders, paid by card, at the counter";
    }
  };

  const classes = useStyles();

  return (
    <div style={{ margin: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          marginBottom: "12px",
          marginLeft: "2px",
        }}
      >
        <Typography variant="h6">{reportName}</Typography>
      </div>
      <CardCommon>
        {!_.isEmpty(nodes) &&
          nodes.map((data: any) => (
            <Card
              className={classes.cardStyle}
              style={{
                marginTop:
                  data.Title === "Cash Payments In-Store" ||
                  data.Title === "Total Sales" ||
                  data.Title === "Total Cash Payments" ||
                  data.Title === "Starting Float" ||
                  data.Title === "Card Payments In-Store"
                    ? "20px"
                    : "opx",
              }}
            >
              <Grid container style={{ placeItems: "center", height: "40px" }}>
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: "16px", fontWeight: "bold" }}
                >
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    {data.Title}

                    {(data.Title === "Cash Payments Online" ||
                      data.Title === "Card Payments Online" ||
                      data.Title === "Card Payments In-Store" ||
                      data.Title === "Cash Payments In-Store") && (
                      <Tooltip title={handleTooltip(data.Title)}>
                        <InfoIcon
                          style={{
                            marginLeft:
                              data.Title === "Card Payments Online" ||
                              data.Title === "Cash Payments Online"
                                ? "21px"
                                : "10px",
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    paddingRight: "16px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {data.Amount}
                </Grid>
              </Grid>
            </Card>
          ))}
      </CardCommon>
    </div>
  );
};

export default BankingAndSummeryInfo;
